import React, { Component } from "react";
import {Route, Router, Switch,} from "react-router-dom";
import { createBrowserHistory } from 'history';
import App from "../app/app";

import "./router.scss"
import { JobsPage } from '../job/job';
import AboutPage from '../about/about';
import TermsAndConditionsPage from '../terms-conditions/terms-conditions';
import ContactPage from '../contact/contact';

import translationInit from "../../language";
import i18next from "i18next";
import Cookie from '../cookie-acceptance/cookie';
import BackToTopBtn from '../back-to-top/BackToTopBtn';

export const history = createBrowserHistory({ basename: "/" });

export default class Routes extends Component {

	state = {
		page: history.location.pathname.split("/").pop(),
		language: "en",
	};

	navigate = (page: string) => (e: any) => {
		e.preventDefault();
		this.setState({ page });
		history.push("/" + page);
		return false;
	};

	redirectGetStarted = (url: string) => {
		const token = localStorage.getItem("token");
		if (token) {
			url = "app/article"
		}

		window.location.href = "/" + url
	};

	changeLanguage(lang: string) {
		return async () => {
			await i18next.changeLanguage(lang).then(r => {console.log(r)});
			await this.setState({...this.state, language: lang});
			window.localStorage.i18nextLng = lang;
		}
	}

	componentWillMount(): void {
		translationInit().then(async () => {
			await i18next.changeLanguage(window.localStorage.i18nextLng || navigator.language.split("-")[0]).then(r => {});
			await this.setState({...this.state, language: window.localStorage.i18nextLng || navigator.language.split("-")[0]});
			console.log('Translation successfully Initialized...');
		});
	}

	render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

		const { page } = this.state;

		return (
			<Router history={history}>
				<header>
					<div>
						<img onClick={this.navigate("")} src="https://storage.googleapis.com/clipsymphony_static_assets/Logo.svg" alt="clipsymphony logo"/>
					</div>
					<div>
						<nav>
							<li>
								<a className={(page === "" || page === "home") ? "active" : ""} onClick={this.navigate("home")}> {i18next.t("Home")} </a>
							</li>
							<li><a className={page === "about" ? "active" : ""} onClick={this.navigate("about")}> {i18next.t("About")} </a></li>
							<li><a onClick={this.navigate("#pricing")} className={page === "#pricing" ? "active" : ""}> {i18next.t("Pricing")} </a></li>
							<li><a className={page === "contact" ? "active" : ""} onClick={this.navigate("contact")}> {i18next.t("Contact Us")} </a></li>
							<li><a onClick={(e) => this.redirectGetStarted("app/login")}> {i18next.t("Login")} </a></li>
							<li className="get-started" onClick={(e) => this.redirectGetStarted("app/signup")}><a> {i18next.t("Get Started")} </a></li>
						</nav>
						<div>
							<span onClick={this.changeLanguage("en")} className={this.state.language === 'en' ? 'active' : ''}> EN </span> &nbsp; &nbsp;
							<span onClick={this.changeLanguage("de")} className={this.state.language === 'de' ? 'active' : ''}> DE </span>
						</div>
					</div>
				</header>
				<div>
					<Switch>
						<Route path="/about"> <AboutPage language={this.state.language}/> </Route>
						<Route path="/contact"> <ContactPage language={this.state.language}/> </Route>
						<Route path="/jobs"> <JobsPage history={history}/> </Route>
						<Route path="/careers"> <JobsPage history={history}/> </Route>
						<Route path="/job"> <JobsPage history={history}/> </Route>
						<Route path="/hiring"> <JobsPage history={history}/> </Route>
						<Route path="/terms"> <TermsAndConditionsPage/> </Route>
						<Route path="/terms-and-conditions"> <TermsAndConditionsPage/> </Route>
						<Route path="/"> <App signUp={this.redirectGetStarted} history={history}/> </Route>
						<Route path="*"> <App signUp={this.redirectGetStarted} history={history}/> </Route>
					</Switch>
				</div>
				<br/>
				<div className="footer">
					<a href="/app/terms-and-conditions">{i18next.t("Terms and Conditions")}</a>
					<a href="/app/privacy-policy"> {i18next.t("Privacy Policy")} </a>
				</div>
				<Cookie language={this.state.language}/>
				<BackToTopBtn/>
			</Router>
		);
	}
}
