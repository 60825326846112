import React, { Component } from 'react';
import CommonTooltips from '../tooltips/Common';

import "./app.scss";
import i18next from 'i18next';
import SubscriptionPlan from "../../constants/plan";


interface IProps {
  className?: string;
  id?: string;
  history?: any;
  signUp: (url: string) => void
}

export default class App extends Component<IProps, any> {

  protected navigateToPricing(){
    const hash = this.props.history.location.hash;
    if (hash && hash == '#pricing'){
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }
  componentDidMount() {
    this.navigateToPricing()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any) {
    this.navigateToPricing()
  }

  public render():
    React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <div className="App">
        <div className="foreword">
          <div>
            <h1>
              {i18next.t("Your professional tool for")}
              <br/>
              {i18next.t("modern media monitoring")}
            </h1>
            <p>
              {i18next.t("ClipSymphony brings your")} <br/> {i18next.t("monitoring and clipping")} <br/> {i18next.t("services together in one tool")}
            </p>
            <p>
              <button> <a href="/app/signup"> {i18next.t("Try it now for free")} </a> </button>
            </p>
          </div>
          <div>
            <img src={"https://storage.googleapis.com/clipsymphony_static_assets/landing%20page%20hero%20image.svg"} alt={"landing page hero image"} />
          </div>
        </div>

        <div className="product-description">
          <div className="collect-and-create">
            <div> <img alt="collect create icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/collect%20and%20create.svg" /> </div>
            <h3> {i18next.t("Collect and create")} </h3>
            <article>
              {i18next.t("Collect all press articles, blog posts, audio and video files in one place and create complete press clippings with just one click")}
            </article>
          </div>

          <div className="working-team">
            <div> <img alt="working ina team icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/working%20in%20a%20team.svg" /> </div>
            <h3> {i18next.t("Working in a team")} </h3>
            <article>
              {i18next.t("No more cumbersome Excel and PDF files, your communications team works in real time with all the clippings that are available")}
            </article>
          </div>

          <div className="easy-publishing">
            <div> <img alt="easy publishing icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/easy%20publishing.svg" /> </div>
            <h3> {i18next.t("Easy publishing")} </h3>
            <article>
              {i18next.t("Whether internally to different departments or externally to customers, Share your media results with the right target group with just a few clicks")}
            </article>
          </div>

          <div className="one-login">
            <div> <img alt="1 login icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/only%201%20login.svg" /> </div>
            <h3> {i18next.t("Only 1 login")} </h3>
            <article>
              {i18next.t("One login for everything, You're using five different clipping providers and social media monitoring tools? With ClipSymphony you have everything in one")}
            </article>
          </div>

        </div>

        <div className="summary">
          {i18next.t("In a nutshell, here is how it works, You only need the link of the order from your clipping service")}
          <br/> <br/>
          {i18next.t("ClipSymphony recognizes the provider with this link and then permanently imports all provided articles into your timeline, You can do this for as many clipping providers as you like")}
        </div>

        <div className="reviews" style={{display: "none"}}>
          <div className="sally-doe">
            <div className="photo-and-name">
              <img alt="user-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Photos/Sally%20Doe.jpg" />
              <h3>Sally Doe</h3>
              <p>{i18next.t("Marketer")}</p>
            </div>
            <div className="review-content">
              {i18next.t("Thanks to ClipSymphony, I finally have the press clippings of all clients in one tool and can create complete reports there, which saves valuable time")}
            </div>
            <div className="review-rating">
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20outline.svg" alt="star-rating" />
            </div>
          </div>

          <div className="olivier-jones">
            <div className="photo-and-name">
              <img alt="user-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Photos/Olivier%20Johns.jpg" />
              <h3>Olivier Jones</h3>
              <p>{i18next.t("Content Creator")}</p>
            </div>
            <div className="review-content">
              {i18next.t("The tool is a blessing, I create different press reviews for each unit in our company, which are fed from different providers, With ClipSymphony, this is now done almost by itself")}
            </div>
            <div className="review-rating">
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
            </div>
          </div>

          <div className="doris-booker">
            <div className="photo-and-name">
              <img alt="user-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Photos/Doris%20Booker.jpg" />
              <h3>Doris Booker</h3>
              <p> {i18next.t("Social Media Specialist")}</p>
            </div>
            <div className="review-content">
              {i18next.t("Finally, no more logging into seven different tools, just one, And distributing our clippings via social media works, too Great")}
            </div>
            <div className="review-rating">
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/star%20filled.svg" alt="star-rating" />
            </div>
          </div>
        </div>

        <div className="try-it">
          <button> <a href="/app/signup"> {i18next.t("Try it now for free")} </a> </button>
        </div>

        <div className="feature-list">
          <h2> {i18next.t("All functions at a glance")} </h2>

          <div className="features">

            <div className="timeline">
              <div className="text">
                <h3> <img alt="timeline" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/timeline.svg" /> {i18next.t("Timeline")} </h3>
                <p>
                  {i18next.t("Similar to Facebook or Twitter, the Timeline clearly displays all messages about your company, regardless of whether the clippings come from Landau Media, PMG, Meltwater or your social media tool")}
                </p>
              </div>
              <div>
                <img alt="timeline" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/timeline.svg" />
              </div>
            </div>

            <div className="filter">
              <div>
                <img alt="filter" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/filter.svg" />
              </div>
              <div className="text">
                <h3> <img alt="filter" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/filter.svg" /> {i18next.t("Filter")} </h3>
                <p>
                  {i18next.t("The timeline can be customized via filters so that only certain messages are visible This makes research much easier")}
                </p>
              </div>
            </div>

            <div className="collections">
              <div className="text">
                <h3> <img alt="collections" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/collections.svg" /> {i18next.t("Collections")} </h3>
                <p>
                  {i18next.t("Individual articles can be assigned to a collection manually or by automated rule This collection is your press review, which is generated fully automatically on request")}
                </p>
              </div>
              <div>
                <img alt="collections" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/collections.svg" />
              </div>
            </div>

            <div className="archive">
              <div>
                <img alt="archive" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/archive.svg" />
              </div>
              <div className="text">
                <h3> <img alt="archive" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/archive.svg" /> {i18next.t("Archive")} </h3>
                <p>
                  {i18next.t("You can push articles and collections into an archive This way you can keep your timeline clear and still access previous content at any time")}
                </p>
              </div>
            </div>

            <div className="tags">
              <div className="text">
                <h3> <img alt="tags" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/tag.svg" /> {i18next.t("Tags")} </h3>
                <p>
                  {i18next.t("Tag articles with as many tags as you like for easy retrieval or further processing later")}
                </p>
              </div>
              <div>
                <img alt="tags" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/tag.svg" />
              </div>
            </div>

            <div className="search">
              <div>
                <img alt="search" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/search.svg" />
              </div>
              <div className="text">
                <h3> <img alt="search" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/search.svg" /> {i18next.t("Search")} </h3>
                <p>
                  {i18next.t("The heart of ClipSymphony is a powerful search function that allows you to create simple, but also very complex search jobs")}
                </p>
              </div>
            </div>

            <div className="scheduled-posting">
              <div className="text">
                <h3> <img alt="scheduled posting" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/schedule.svg" /> {i18next.t("Scheduled Posting")} </h3>
                <p>
                  {i18next.t("You can set up recurring actions - such as sharing clippings on social networks or the intranet - in a scheduled and automated way")}
                </p>
              </div>
              <div>
                <img alt="scheduled posting" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/schedule.svg" />
              </div>
            </div>

            <div className="bulk-edit">
              <div>
                <img alt="bulk-edit" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/edit.svg" />
              </div>
              <div className="text">
                <h3> <img alt="bulk-edit" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/edit.svg" /> {i18next.t("Bulk edit")} </h3>
                <p>
                  {i18next.t("All important editing steps (tagging, deleting, sharing, renaming, etc), you can also do for several articles or collections at the same time")}
                </p>
              </div>
            </div>

            <div className="export">
              <div className="text">
                <h3> <img alt="export" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/export.svg" /> {i18next.t("Export")} </h3>
                <p>
                  {i18next.t("Created collections can be exported as RSS, PDF, XML and JSON This allows you to easily send the data to the customer, but also to process it internally")}
                </p>
              </div>
              <div>
                <img alt="export" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/export.svg" />
              </div>
            </div>

            <div className="social-sharing">
              <div>
                <img alt="social-sharing" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/share.svg" />
              </div>
              <div className="text">
                <h3> <img alt="social-sharing" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/share.svg" /> {i18next.t("Social Sharing")} </h3>
                <p>
                  {i18next.t("Share your articles directly from the tool and without further login on social networks like Facebook, Twitter, LinkedIn, YouTube or Instagram")}
                </p>
              </div>
            </div>

            <div className="content-creation">
              <div className="text">
                <h3> <img alt="content-creation" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/content%20creation.svg" /> {i18next.t("Content Creation")} </h3>
                <p>
                  {i18next.t("Create your own articles and add videos, images or data from your collections")}
                </p>
              </div>
              <div>
                <img alt="content-creation" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/content%20creation.svg" />
              </div>
            </div>

            <div className="multilingualism">
              <div>
                <img alt="multilingualism" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/translation.svg" />
              </div>
              <div className="text">
                <h3> <img alt="multilingualism" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/translation.svg" /> {i18next.t("Multilingualism")} </h3>
                <p>
                  {i18next.t("The ClipSymphony user interface can be used in German and English Therefore you can also work together in international teams")}
                </p>
              </div>
            </div>

            <div className="team-player">
              <div className="text">
                <h3> <img alt="team-player" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/team.svg" /> {i18next.t("Team Player")} </h3>
                <p>
                  {i18next.t("Users receive their own profile with different rights, This allows the entire communication team to access the tool securely and conveniently")}
                </p>
              </div>
              <div>
                <img alt="team-player" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/team.svg" />
              </div>
            </div>

            <div className="social-teams">
              <div>
                <img alt="social-teams" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/social%20teams.svg" />
              </div>
              <div className="text">
                <h3> <img alt="social-teams" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/social%20teams.svg" /> {i18next.t("Social Teams")} </h3>
                <p>
                  {i18next.t("Social media accounts can be activated for entire teams so that several employees have access to them")}
                </p>
              </div>
            </div>

            <div className="structure">
              <div className="text">
                <h3> <img alt="structure" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/structure.svg" /> {i18next.t("Structure")} </h3>
                <p>
                  {i18next.t("ClipSymphony is based on the reality of work and works in the three hierarchies of companies, teams and users")}
                </p>
              </div>
              <div>
                <img alt="structure" src="https://storage.googleapis.com/clipsymphony_static_assets/home/landing/Icons/structure.svg" />
              </div>
            </div>

          </div>
        </div>

        <section className="pricing-section" id="pricing">
          <div className="price-panel">
            <div className="panel-header">
              <div className="ribbon"></div>
              <div className="header">
                <p>{i18next.t("Basic Plan")}</p>
              </div>
            </div>
            <div className="panel-body">
              <p>{i18next.t("Ideal for individuals")}</p>
              <div className="body-content mini-list">
                <ol>
                  <li>
                    <span className="icon">
                      <img className="check-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/pricing%20check%20-%20white.svg" alt=""/>
                    </span>
                    <p>{i18next.t("Free For Personal Use")}</p>
                  </li>
                  <li>
                    <span className="icon">
                      <img className="check-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/pricing%20check%20-%20white.svg" alt=""/>
                    </span>
                    <p>{i18next.t("2 GB Free Storage")}</p>
                  </li>
                  <li className="mini-list">
                    <ol>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20blue.svg" alt=""/>
                        </span>
                        <p>{i18next.t("025 EUR per GB per Month Beyond Free Storage")}</p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <div className="panel-footer">
              <button onClick={() => this.props.signUp("app/signup")} className="price-btn">{i18next.t("Signup")}</button>
            </div>
          </div>
          <div className="price-panel bg-blue">
            <div className="price-tag bg-white">
              <div>
                <p className="text-blue">
                  {i18next.t("Risk-Free")} <br/>{i18next.t("Trial")}
                </p>
              </div>
            </div>
            <div className="panel-header">
              <div className="ribbon bg-white" />
              <div className="header text-white">
                <p className="text-white">{i18next.t("Professional Plan")}</p>
              </div>
            </div>
            <div className="panel-body">
              <p className="text-white">{i18next.t("Ideal for small businesses and startups")}</p>
              <div className="body-content mini-list">
                <ol>
                  <li>
                    <span className="icon">
                      <img className="check-icon-arrow" src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/arrow%20back%20-%20white.svg" alt=""/>
                    </span>
                    <p className="text-white" style={{marginLeft: '-0.2rem'}}>{i18next.t("Everything in Basic plus")}:</p>
                  </li>
                  <li>
                    <span className="icon">
                      <img className="check-icon" src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/pricing%20check%20-%20blue.svg" alt=""/>
                    </span>
                    <p className="text-white" style={{fontSize: '16px'}}>{i18next.t("Create Organizations And Teams")}</p>
                  </li>
                  <li className="mini-list">
                    <ol >
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("1 Month Free Trial")}</p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("15 EUR Per")} <CommonTooltips language={i18next.language} title={i18next.t("Users logged in for more than 30days")} content={(<>{i18next.t("Active User")}</>)}/> {i18next.t("Billed Monthly")}</p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("Automatic billing stop for")} <CommonTooltips language={i18next.language} title={i18next.t("Users not logged in for 30+ days accounts retained but not billed")} content={(<>{i18next.t("Inactive users")}</>)}/></p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("2 GB Free Storage Per")} <CommonTooltips language={i18next.language} title={i18next.t("Users logged in for more than 30days")} content={(<>{i18next.t("Active users")}</>)}/></p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("025 EUR per GB Beyond Free Storage")}</p>
                      </li>
                      {/*<li>*/}
                      {/*  <span className="icon">*/}
                      {/*    <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>*/}
                      {/*  </span>*/}
                      {/*  <p className="text-white">{i18next.t("Billed Monthly")}</p>*/}
                      {/*</li>*/}
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <div className="panel-footer">
              <button onClick={() => this.props.signUp(`app/signup?plan=${SubscriptionPlan.getProfessionalPlanName()}`)} className="price-btn bg-white text-blue">{i18next.t("Subscribe")}</button>
            </div>
          </div>
          <div className="price-panel bg-blue">
            <div className="price-tag bg-white">
              <div>
                <p className="text-blue">
                  {i18next.t("Risk-Free")} <br/>{i18next.t("Trial")}
                </p>
              </div>
            </div>
            <div className="panel-header">
              <div className="ribbon bg-white"/>
              <div className="header text-white">
                <p className="text-white">{i18next.t("Enterprise Plan")}</p>
              </div>
            </div>
            <div className="panel-body">
              <p className="text-white">{i18next.t("Ideal for enterprises and corporations")}</p>
              <div className="body-content mini-list">
                <ol>
                  <li>
                    <span className="icon">
                      <img className="check-icon-arrow" src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/arrow%20back%20-%20white.svg" alt=""/>
                    </span>
                    <p className="text-white" style={{marginLeft: '-0.2rem'}}>{i18next.t("Everything in Professional plus")}:</p>
                  </li>
                  <li className="mini-list">
                    <ol>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("Available")} <CommonTooltips language={i18next.language} title={i18next.t("Make quick easy payments directly from bank accounts with no extra fees")} content={(<>{i18next.t("Bank Transfer Payments")}</>)}/></p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("Minimum 20")} <CommonTooltips language={i18next.language} title={i18next.t("Users logged in for more than 30days")} content={(<>{i18next.t("Active users")}</>)}/></p>
                      </li>
                      <li>
                        <span className="icon">
                          <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/dot%20-%20white.svg" alt=""/>
                        </span>
                        <p className="text-white">{i18next.t("Automatic billing stop for")} <CommonTooltips language={i18next.language} title={i18next.t("Users not logged in for 30+ days accounts retained but not billed")} content={(<>{i18next.t("Inactive users")}</>)}/></p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <div className="panel-footer">
              <button onClick={() => this.props.signUp(`app/signup?plan=${SubscriptionPlan.getEnterprisePlanName()}`)} className="price-btn bg-white text-blue">{i18next.t("Subscribe")}</button>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

