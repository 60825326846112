import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./cookie.scss";

/**
 * @file .
 */

interface IProps {
	language: string;
	className?: string;
}


const Cookie = (props: IProps) => {
	// the state to hold the response
	const [response, setResponse] = useState(window.localStorage.acceptCookieResponse);
	// whether the customization box pops up or not
	const [customizationPopup, setCustomizationPopup] = useState(true);
	// the page of the customization popup
	const [customizationPopupSelectCookies, setCustomizationPopupSelectCookies] = useState(false);
	// cookies we need to set for user preferences
	const [analyticalCookies, setAnalyticalCookies] = useState(false);
	const [marketingCookies, setMarketingCookies] = useState(false);

	// this is the part where we keep the cookie page at a full screen
	// after the full screen is over, we make the display normal
	useEffect(() => {
		if(!response) {
			// scroll to the top of the page
			window.scroll(0, 0);
			document.body.style.overflow = 'hidden';
		}
		return () => { document.body.style.overflow = 'auto'; }
	}, []);

	const cookieFunctionResponse = (response:string) => (e:any) => {
		document.body.style.overflow = 'auto';
		window.localStorage.acceptCookieResponse = response;
		setResponse(response);
	};

	// function to set the customization popup
	const openCustomizationPopup = async () => {
		await setCustomizationPopup(true);
		// scroll to the top of the page when the dialog mounts
		window.scrollTo(0,0);
	};

	const openCustomizationPopupSelectCookies = async () => {
		await setCustomizationPopupSelectCookies(!customizationPopupSelectCookies);
	};

	const saveSettings = async () => {
		let acceptString = "accept-essential";
		if(analyticalCookies) acceptString += "-analytics";
		if(marketingCookies) acceptString += "-marketing";
		// user has checked all
		if(acceptString === "accept-essential-analytics-marketing") acceptString = "accept";
		// set the response and close the cookie consent form
		cookieFunctionResponse(acceptString)(null);
	};

	// if a cookie response already exists
	if(response) return null;

	// the customization popup is up
	if(customizationPopup) {

		// this is the default component that shows up with the popup
		let customizationPopupComponent = <>
				<div className={"space-y-4"}>
						<h2 className={"font-['Lato_Bold'] text-xl"}> {i18next.t("Cookie Consent and Data Processing")} </h2>
						<p className={"font-['Lato_Regular'] "}>
							{i18next.t("ClipSymphony uses cookies to improve your experience on our website")}.
							{i18next.t("Some cookies are essential for the website to function, while others help us to improve your experience by providing us with insights into how you use our website")}.
						</p>
						<p className={"font-['Lato_Regular'] "}>
							{i18next.t("By clicking on Accept all, you agree to allow us to access your device and to process your data, including the creation and processing of individual usage profiles across websites and across partners and devices")}.
							{i18next.t("We may also transfer your data to third-party providers, some of whom may process your data in countries outside the European Union, such as the United States")}.
						 {i18next.t("Please note that the level of data protection in these countries may not be equivalent to the level of data protection in the European Union")}.
						</p>
				</div>
				<div className="action-buttons w-full self-end flex justify-between">
					<section>
						<button onClick={openCustomizationPopupSelectCookies} className="border border-black rounded text-black py-2 px-4">
							<img width={20} height={20} src={"https://storage.googleapis.com/clipsymphony_static_assets/Icons/settings%20-%20black.svg"} /> {i18next.t("Change Settings")}
						</button>
					</section>
					<section className={"space-x-4"}>
						<button onClick={cookieFunctionResponse("accept-required")} className="border border-clip-blue rounded text-clip-blue py-2 px-4">
							{i18next.t("Only Required")}
						</button>
						<button onClick={cookieFunctionResponse("accept")} className="border border-clip-blue rounded text-white bg-clip-blue py-2 px-4">
							{i18next.t("Agree to All")}
						</button>
					</section>
				</div>
		</>;

		// if the user chooses to select their own
		// cookies to be used for tracking
		if(customizationPopupSelectCookies) {
			customizationPopupComponent = <>
				<h2 className={"font-['Lato_Bold'] text-xl"}> {i18next.t("Manage your Data and Privacy settings")} </h2>
				<p>
					{i18next.t("ClipSymphony uses cookies to provide you with an optimized website experience")}.
					{i18next.t("These cookies include essential cookies, analytical cookies, and marketing cookies, We want to give you the choice of which cookies are permitted")}:
				</p>

				<div className="space-y-4">
						<div className={"grid grid-cols-[1fr_70px] grid-flow-col"}>
							<div className="pr-4">
								<h5 className={"font-['Lato_Bold']"}>{i18next.t("Essential cookies")}</h5>
								<p>{i18next.t("Essential cookies are necessary for the website to function and cannot be switched off in our systems")}. {i18next.t("These cookies are used to")}:</p>
								<ul>
								<li>{i18next.t("Keep track of your login status")}</li>
								<li>{i18next.t("Remember your language preference")}</li>
								</ul>
							</div>
							<label className="switch self-center">
								<input type="checkbox" checked={true} />
									<span className="slider round" />
							</label>
						</div>

					<div className={"grid grid-cols-[1fr_70px] grid-flow-col"}>
						<div className="pr-4">
							<h5 className={"font-['Lato_Bold']"}>{i18next.t("Analytical cookies")}</h5>
							<p>{i18next.t("")}. {i18next.t("These cookies are used to")}:</p>
							<ul>
								<li>{i18next.t("Track the number of visitors to our website")}</li>
								<li>{i18next.t("Track the pages that you visit on our website")}</li>
								<li>{i18next.t("Track how long you spend on our website")}</li>
							</ul>
						</div>
						<label className="switch self-center">
							<input onChange={() => {setAnalyticalCookies(!analyticalCookies)}} checked={analyticalCookies} type="checkbox" />
							<span className="slider round" />
						</label>
					</div>

					<div className={"grid grid-cols-[1fr_70px] grid-flow-col"}>
						<div className="pr-4">
							<h5 className={"font-['Lato_Bold']"}>{i18next.t("Marketing cookies")}</h5>
							<p>{i18next.t("")}. {i18next.t("These cookies are used to")}:</p>
							<ul>
								<li>{i18next.t("Track the websites that you visit")}</li>
								<li>{i18next.t("Show you advertising that is relevant to your interests")}</li>
							</ul>
						</div>
						<label className="switch self-center">
							<input onChange={() => {setMarketingCookies(!marketingCookies)}} checked={marketingCookies} type="checkbox" />
							<span className="slider round" />
						</label>
					</div>

					<p>
						{i18next.t("You can choose whether to accept or decline cookies")}.
						{i18next.t("If you decline cookies, you may not be able to use all of the features on our website")}.
						<br/>
						<br/>
						{i18next.t("For more information, please refer to our")}  <a className={"text-clip-blue underline"} href={"/app/privacy-policy"}> {i18next.t("privacy policy")} </a>.
						<br/>
						<br/>
						<br/>
					</p>

				</div>
				<div className="action-buttons w-full self-end flex justify-between">
					<section>
						<button onClick={openCustomizationPopupSelectCookies} className="border border-black rounded text-black py-2 px-4">
							<img width={20} height={20} src={"https://storage.googleapis.com/clipsymphony_static_assets/Icons/back%20-%20black.svg"} /> {i18next.t("Go Back")}
						</button>
					</section>
					<section className={"space-x-4"}>
						<button onClick={saveSettings} className="border border-clip-blue rounded text-clip-blue py-2 px-4">
							{i18next.t("Save Settings")}
						</button>
						<button onClick={cookieFunctionResponse("accept")} className="border border-clip-blue rounded text-white bg-clip-blue py-2 px-4">
							{i18next.t("Accept All")}
						</button>
					</section>
				</div>
			</>
		}

		return <dialog id="dialog" className="grid" open={true} style={{ width: "100%", border: "none", minHeight: "100vh", height: "100vh", overscrollBehavior: "contain", overflow: "scroll", backgroundColor: "rgba(255,255,255,0.8)", top: 0, padding: 0 }}>
			<div className="w-[100%] sm:w-[50%] shadow border font-['Lato_Regular'] space-y-2 grid px-4 py-8 self-center min-h-[70vh] mx-auto rounded border bg-white">
				{ customizationPopupComponent }
			</div>
		</dialog>
	}

	return (
		<div id={"cookies"} className="border-box z-[1000] bg-clip-blue fixed bottom-0 text-white w-full">
			<h1 className={"mb-2 text-2xl font-['Lato_Bold']"}> { i18next.t("Privacy Notice") } </h1>
			<div className={"grid auto-cols-auto grid-flow-col w-full justify-space-between"}>
				<div className={"font-['Lato_Regular']"}>
					<p> {i18next.t("Our site collects and securely store information about you, including your preferences, behavior, and device")}.</p>
					<p> {i18next.t("This data enables us to analyze website traffic, personalize content, and offer social media features")}.</p>
					<p> {i18next.t("Because we care about your privacy, you can decide whether to allow or reject the use of the technology")}.</p>
				</div>
				<div className={"text-right self-center text-xs space-x-4"}>
					{/*<button onClick={cookieFunctionResponse("decline")} className={"px-4 py-3 bg-gray-200 text-black font-['Lato_Bold'] text-clip-blue rounded"}>{i18next.t("Decline All Cookies")}</button>*/}
					<button onClick={openCustomizationPopup} className={"px-4 py-3 bg-gray-200 text-black font-['Lato_Bold'] text-clip-blue rounded"}> <img width={20} height={20} src={"https://storage.googleapis.com/clipsymphony_static_assets/Icons/settings%20-%20black.svg"} /> &nbsp; {i18next.t("Customize cookies")}</button>
					<button onClick={cookieFunctionResponse("accept")} className={"px-5 py-3 bg-clip-blue border border-white text-white font-['Lato_Bold'] text-clip-blue rounded"}>{i18next.t("Accept All Cookies")}</button>
				</div>
			</div>
		</div>
	)
};


export default connect(undefined, undefined)(Cookie);

