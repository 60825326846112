import './tooltip.scss';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';


interface IProps {
	title: string;
	content: JSX.Element
	className?: string
	language: string
}

let tooltipCounter = 0;

const CommonTooltips:(props: IProps) => JSX.Element = (props: IProps) => {
	// increase the tooltip count
	tooltipCounter++;
	return (
		<>
			<span className={`tooltip ${props.className}`} data-tip data-for={`tooltip-${tooltipCounter}`}> {props.content} </span>
			{/*@ts-ignore*/}
			<ReactTooltip place="bottom" type="dark" effect="float" id={`tooltip-${tooltipCounter}`}> <span> {props.title} </span> </ReactTooltip>
		</>
	)
};

export default connect(undefined, undefined)(CommonTooltips);
