import React, { Component } from 'react';
import "./contact.scss"
import i18next from 'i18next';
import { Request } from '../../auth/request';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
	className?: string;
	id?: string;
	language: string;
	history?: any;
}

export default class ContactPage extends Component<IProps, any> {
	
	state: { name: string, nameError: string, email: string, emailError: string, subject: string, subjectError: string, message: string, messageError: string, postMessage: string, postStatus: string, recaptchaToken: string, recaptchaTokenError: string } = {
		name: "",
		nameError: "",
		email: "",
		emailError: "",
		subject: "",
		subjectError: "",
		message: "",
		messageError: "",
		recaptchaToken: "",
		recaptchaTokenError: "",
		postMessage: "",
		postStatus: "",
	};
	
	change = (name:string) => async (e:any) => {
	  // we're handling sending an email here
   let message = `${name} field cannot be empty`;

   // if this is the email field and it's not empty.
	  if(name === "email") {
	    // test to see if the regex has a match for email field.
     // so, if this email is not valid.
	    if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e.target.value)) {
	      message = "Please input a valid email";
		    return this.setState({[name]: e.target.value, [name+"Error"]: message});
     }
   }

	  // then update the state
	  await this.setState({[name]: e.target.value, [name+"Error"]: !e.target.value ? message : ""})
 };
	
	submitForm = (e:any) => {
			// check for errors
			if(!this.state.name || !this.state.email || !this.state.message || !this.state.subject || !this.state.recaptchaToken) {
				return this.setState({
					nameError: this.state.name ? "" : (this.state.nameError || "Name field cannot be empty"),
					emailError: this.state.email ? "" : (this.state.emailError || "Email field cannot be empty"),
					messageError: this.state.message ? "" : (this.state.messageError || "Message field cannot be empty"),
					subjectError: this.state.subject ? "" : (this.state.subjectError || "Subject field cannot be empty"),
					recaptchaTokenError: this.state.recaptchaToken ? "" : (this.state.recaptchaTokenError || "Please complete captcha challenge"),
				});
			}
			
			Request
				.post("/api/contact-form", {
					name: this.state.name,
					email: this.state.email,
					subject: this.state.subject,
					message: this.state .message,
					recaptchaToken: this.state.recaptchaToken
				}).then(r => {
						this.setState({postMessage: r.message, postStatus: r.status });
			}).catch((err) => {
						this.setState({postMessage: "Error making request Please try again", postStatus: "500" });
			})
			
	};
	
	captchaChange = async (res:any) => {
		await this.setState({recaptchaToken: res, recaptchaTokenError: ""});
		return false;
	};
	
	captchaExpired = async (e:any) => {
		await this.setState({recaptchaToken: "", recaptchaTokenError: "Please complete captcha challenge"});
		return false;
	};
	
	public render():
		React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
		return (
			<div className="contact">
				<div className="contact-intro">
					<div>
						<h1> {i18next.t("Contact Us")}! </h1>
						<p>
							{i18next.t("Any question or remarks")}<br/>
							{i18next.t("just write us a message")}
						</p>
					</div>
					<div>
						<img src="https://storage.googleapis.com/clipsymphony_static_assets/home/contact/vector-shot.png" alt="contact"/>
					</div>
				</div>
				<div className="form">
					<div>
						<input onChange={this.change("name")} placeholder={i18next.t("Name")} type="text"/>
       { this.state.nameError && <span className={"red"}> { i18next.t(this.state.nameError) } </span> }
					</div>
					<div>
       <input onChange={this.change("email")} placeholder={i18next.t("Email")} type="email"/>
       { this.state.emailError && <span className={"red"}> { i18next.t(this.state.emailError) } </span> }
					</div>
					<div>
       <input onChange={this.change("subject")} placeholder={i18next.t("Subject")} type="text"/>
       { this.state.subjectError && <span className={"red"}> { i18next.t(this.state.subjectError) } </span> }
					</div>
					<div>
       <textarea onChange={this.change("message")} placeholder={i18next.t("Message")}/>
       { this.state.messageError && <span className={"red"}> { i18next.t(this.state.messageError) } </span> }
					</div>
					<div>
						<ReCAPTCHA
							className={"g-recaptcha"}
							sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
							onChange={this.captchaChange}
							onExpired={this.captchaExpired}
							onError={this.captchaExpired}
						/>
      { this.state.recaptchaTokenError && <section style={{textAlign:"center", color: "red"}} className={"red"}> { i18next.t(this.state.recaptchaTokenError) } </section> }
					</div>
					<div>
						<button onClick={this.submitForm}> {i18next.t("Send")} </button>
      { this.state.postMessage && <div className={`post-status status-${this.state.postStatus}`}> { i18next.t(this.state.postMessage) } </div> }
					</div>
				</div>
			</div>
		);
	}
}

